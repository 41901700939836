<template>
  <div id="rollen">
    <CCard>
      <CCardHeader>
        <CRow>
          <CCol sm="8">
            <CIcon name="cil-storage"/>
            <h5>Übersicht über aller Rollen und Rechte</h5>
          </CCol>
        </CRow>
      </CCardHeader>
      <CCardBody>
      <CRow>
        <CCol sm="12">

          <CTabs>
            <CTab>
              <template slot="title">
                Report/Verwaltung
              </template>
              <CDataTable
                :tableFilter="{ label: 'Suchen:', placeholder: 'hier tippen...' }"
                column-filter
                sorter
                striped
                hover
                :items="items.report"
                :fields="fields"
              >
                <template slot="tableFilter">
                  Test
                </template>
                <template #no-items-view><NoData/></template>
                <template #access="{item}">
                  <td nowrap="" width="1%">
                    <CBadge v-if="item.access === 'ja'">Ja <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check2" viewBox="0 0 16 16">
                      <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z"/>
                    </svg></CBadge>
                    <CBadge v-else>Nein <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x" viewBox="0 0 16 16">
                      <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                    </svg></CBadge>
                  </td>
                </template>
              </CDataTable>
            </CTab>
            <CTab>
              <template slot="title">
                Mitarbeiter/Monteure
              </template>
              <CDataTable
                :tableFilter="{ label: 'Suchen:', placeholder: 'hier tippen...' }"
                striped
                hover
                column-filter
                sorter
                :items="items.mitarbeiter"
                :fields="fields"
              >
                <template #no-items-view><NoData/></template>
                <template #access="{item}">
                  <td nowrap="" width="1%">
                    <CBadge v-if="item.access === 'ja'">Ja <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check2" viewBox="0 0 16 16">
                      <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z"/>
                    </svg></CBadge>
                    <CBadge v-else>Nein <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x" viewBox="0 0 16 16">
                      <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                    </svg></CBadge>
                  </td>
                </template>
              </CDataTable>
            </CTab>
            <CTab>
              <template slot="title">
                Kalender
              </template>
              <CDataTable
                :tableFilter="{ label: 'Suchen:', placeholder: 'hier tippen...' }"
                striped
                column-filter
                sorter
                hover
                :items="items.kalender"
                :fields="fields"
              >
                <template #no-items-view><NoData/></template>
                <template #access="{item}">
                  <td nowrap="" width="1%">
                    <CBadge v-if="item.access === 'ja'">Ja <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check2" viewBox="0 0 16 16">
                      <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z"/>
                    </svg></CBadge>
                    <CBadge v-else>Nein <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x" viewBox="0 0 16 16">
                      <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                    </svg></CBadge>
                  </td>
                </template>
              </CDataTable>
            </CTab>
            <CTab>
              <template slot="title">
                 Admin
              </template>
              <CDataTable
                :tableFilter="{ label: 'Suchen:', placeholder: 'hier tippen...' }"
                columnFilter
                striped
                hover
                :items="items.admin"
                :fields="fields"
              >
                <template #no-items-view><NoData/></template>
                <template #access="{item}">
                  <td nowrap="" width="1%">
                    <CBadge v-if="item.access === 'ja'">Ja <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check2" viewBox="0 0 16 16">
                      <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z"/>
                    </svg></CBadge>
                    <CBadge v-else>Nein <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x" viewBox="0 0 16 16">
                      <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                    </svg></CBadge>
                  </td>
                </template>
              </CDataTable>
            </CTab>
          </CTabs>

        </CCol>
      </CRow>
      </CCardBody>
      <CCardFooter>
      <CRow>
        <CCol sm="2">
          <CButton class="" color="secondary" size="sm" v-on:click="$router.push('/mitarbeiter/uebersicht')" ><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-left" viewBox="0 0 16 16">
            <path fill-rule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"/>
          </svg> zurück</CButton>
          <br/>
          <br/>
        </CCol>
      </CRow>
      </CCardFooter>
    </CCard>
  </div>
</template>

<script>

import { adminRights } from '@/views/Mitarbeiter/Rollen/admin'
import { mitarbeiterRights } from '@/views/Mitarbeiter/Rollen/mitarbeiter'
import { kalenderRights } from '@/views/Mitarbeiter/Rollen/kalender'
import { reportRights } from '@/views/Mitarbeiter/Rollen/report'

export default {
  name: 'mitarbeiter-rollen',
  data () {
    return {
      columnFilters: {
        access: {
          value: 'test'
        }
      },
      fields: [
        {
          key: 'name',
          label: 'Navigation/Reiter',
          _style: 'width:10%'
        },
        {
          key: 'function',
          label: 'Funktion',
          _style: 'width:10%'
        },
        {
          key: 'access',
          label: 'Zugang ?',
          _style: 'width:20%'
        }
      ],
      items: {
        admin: adminRights,
        report: reportRights,
        mitarbeiter: mitarbeiterRights,
        kalender: kalenderRights
      }
    }
  },
  components: {
  },
  props: {
    protokolle: {
      type: [Array, Object],
      required: true
    }
  },
  mounted () {
  }
}
</script>
