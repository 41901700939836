export const kalenderRights = [
  {
    name: 'Protokoll',
    function: 'Übersicht',
    access: 'nein'
  },
  {
    name: 'Protokoll',
    function: 'Protokoll speichern',
    access: 'nein'
  },
  {
    name: 'Protokoll',
    function: 'Protokoll drucken',
    access: 'nein'
  },
  {
    name: 'Tageskalender',
    function: 'Übersicht',
    access: 'ja'
  },
  {
    name: 'Tageskalender',
    function: 'Drucken',
    access: 'ja'
  },
  {
    name: 'Tageskalender',
    function: 'Dem Tageskalender hinzufügen',
    access: 'ja'
  },
  {
    name: 'Tageskalender',
    function: 'Tageskalender Suche',
    access: 'nein'
  },
  {
    name: 'Tageskalender',
    function: 'Tageskalender Kopieren',
    access: 'nein'
  },
  {
    name: 'Report',
    function: 'Übersicht',
    access: 'nein'
  },
  {
    name: 'Report',
    function: 'Einträge Filtern',
    access: 'nein'
  },
  {
    name: 'Kunden',
    function: 'Übersicht',
    access: 'nein'
  },
  {
    name: 'Kunden',
    function: 'Neuer Kunde',
    access: 'nein'
  },
  {
    name: 'Kunden',
    function: 'Kunde editieren',
    access: 'nein'
  },
  {
    name: 'Kunden',
    function: 'Kunde inaktiv setzen',
    access: 'nein'
  },
  {
    name: 'Projekte',
    function: 'Übersicht',
    access: 'nein'
  },
  {
    name: 'Projekte',
    function: 'Übersicht',
    access: 'nein'
  },
  {
    name: 'Projekte',
    function: 'Neues Projekt',
    access: 'nein'
  },
  {
    name: 'Projekte',
    function: 'Projekt Edititeren',
    access: 'nein'
  },
  {
    name: 'Projekte',
    function: 'Projekt inaktiv setzen',
    access: 'nein'
  },
  {
    name: 'Vorgaben',
    function: 'allgemeine Freizeiten / Fortbildungen',
    access: 'nein'
  },
  {
    name: 'Vorgaben',
    function: 'neue Freizeit / Fortbildung',
    access: 'nein'
  },
  {
    name: 'Vorgaben',
    function: 'editieren Freizeit / Fortbildung',
    access: 'nein'
  },
  {
    name: 'Vorgaben',
    function: 'löschen Freizeit / Fortbildung',
    access: 'nein'
  },
  {
    name: 'Vorgaben',
    function: 'Leistungen Übersicht',
    access: 'nein'
  },
  {
    name: 'Vorgaben',
    function: 'Leistungen Anlegen',
    access: 'nein'
  },
  {
    name: 'Vorgaben',
    function: 'Leistungen Editieren',
    access: 'nein'
  },
  {
    name: 'Vorgaben',
    function: 'Leistungen Inaktiv setzen',
    access: 'nein'
  },
  {
    name: 'Vorgaben',
    function: 'Textbausteine Übersicht',
    access: 'nein'
  },
  {
    name: 'Vorgaben',
    function: 'Textbausteine Anlegen',
    access: 'nein'
  },
  {
    name: 'Vorgaben',
    function: 'Textbausteine Editieren',
    access: 'nein'
  },
  {
    name: 'Vorgaben',
    function: 'Textbausteine Inaktiv setzen',
    access: 'nein'
  },
  {
    name: 'Vorgaben',
    function: 'Abwesenheiten Übersicht',
    access: 'nein'
  },
  {
    name: 'Vorgaben',
    function: 'Abwesenheiten Anlegen',
    access: 'nein'
  },
  {
    name: 'Vorgaben',
    function: 'Abwesenheiten Editieren',
    access: 'nein'
  },
  {
    name: 'Vorgaben',
    function: 'Abwesenheiten Inaktiv setzen',
    access: 'nein'
  },
  {
    name: 'Team',
    function: 'Übersicht',
    access: 'nein'
  },
  {
    name: 'Team',
    function: 'Rollen und Rechte',
    access: 'nein'
  },
  {
    name: 'Team',
    function: 'Anlegen',
    access: 'nein'
  },
  {
    name: 'Team',
    function: 'Bearbeiten',
    access: 'nein'
  },
  {
    name: 'Team',
    function: 'inaktiv setzen',
    access: 'nein'
  },
  {
    name: 'Team',
    function: 'Passwort ändern',
    access: 'nein'
  },
  {
    name: 'Benutzer',
    function: 'Passwort ändern',
    access: 'ja'
  },
  {
    name: 'Benutzer',
    function: 'logout',
    access: 'ja'
  }
]
